import {
  getPermissionIcon, PermissionCategories, PermissionCategory, PermissionInfoDefinition
} from "@lib/models/permissions.models";
import {ArCmsPermissions, arPermissionMap} from "@cms/ar-cms/ar-cms.permissions";
import {AuthCmsPermissions, authPermissionMap} from "@cms/auth-cms/auth-cms.permissions";
import {ToolingCmsPermissions, toolingPermissionMap} from "@cms/tooling-cms/tooling-cms.permissions";
import {EmbeddedCmsPermissions, embedPermissionMap} from "@cms/embedded-cms/embedded-cms.permissions";
import {ConnectCmsPermissions, connectPermissionMap} from "@cms/connect-cms/connect-cms.permissions";
import {objToArr} from "@juulsgaard/ts-tools";
import {AdminUserInfoModel} from "@cms/auth-cms/stores/admins/admins.models";

export interface PermissionStoreState {
  roles?: AdminAuthRole[];
}

export interface AuthRole {
  id: string;
  name: string;
}

export interface AuthRoleInfo extends AuthRole {
  id: string;
  name: string;
  permissions: Permissions[];
}

export interface AdminAuthRole extends AuthRole {
  description?: string;
  azureGroupId?: string;
  permissions: Permissions[];
  users: AdminUserInfoModel[];
}

export interface AuthRoleCreateModel {
  name: string;
}

export interface AuthRolePatchModel {
  id: string;
  name?: string;
  description?: string;
  azureGroupId?: string;
  permissions?: Permissions[];
}

export interface PermissionInfo extends PermissionInfoDefinition {
  icon: string;
  id: Permissions;
}


export const Permissions = {...AuthCmsPermissions, ...ToolingCmsPermissions, ...ArCmsPermissions, ...EmbeddedCmsPermissions, ...ConnectCmsPermissions};
export type Permissions = keyof typeof Permissions;

const permissionDataMap: { [key in Permissions]: PermissionInfoDefinition } = {...authPermissionMap, ...toolingPermissionMap, ...arPermissionMap, ...embedPermissionMap, ...connectPermissionMap};


export const permissionCategories = Object.values(PermissionCategories).map((cat) => ({id: cat, name: cat} as PermissionCategory));

export const permissionData: PermissionInfo[] = objToArr(permissionDataMap, (data, key) => ({
  ...data,
  id: key as Permissions,
  icon: data.icon ?? getPermissionIcon(key),
}));
