import {computed, inject, Injectable, signal, Signal} from '@angular/core';
import {AdminAuthRole, AuthRole, Permissions} from "@cms/auth-cms/stores/permission/permission.models";
import {AuthStoreState} from "@core/stores/auth/auth.models";
import {AdminUserAuthModel} from "@cms/auth-cms/stores/admins/admins.models";
import {AuthStore} from "@core/stores/auth/auth.store";
import {AuthTokenService} from "@core/services/auth-token.service";
import {toSignal} from "@angular/core/rxjs-interop";
import {arrToSet} from "@juulsgaard/ts-tools";
import {formatUserName} from "@lib/helpers/user-helpers";

@Injectable({providedIn: 'root'})
export class SessionService implements SessionData {

  static allPermissions: Permissions[] = Object.values(Permissions);
  static adminRoles: AuthRole[] = [{id: 'admin', name: 'CMS Admin'}];

  //<editor-fold desc="User Data">
  readonly user: Signal<AdminUserAuthModel | undefined>;

  readonly authenticated: Signal<boolean>;
  readonly admin: Signal<boolean>;
  readonly displayName: Signal<string>;

  readonly permissions: Signal<Set<Permissions>>;
  readonly roles: Signal<AuthRole[]>
  //</editor-fold>

  private state: Signal<AuthStoreState>;

  constructor(private tokenService: AuthTokenService) {

    this.state = toSignal(inject(AuthStore).state$, {initialValue: {}});

    this.user = computed(() => this.state().user);

    const permissions = computed(() => this.state().permissions ?? []);
    const permissionArr = computed(() => {
      if (!this.user()?.isSuperAdmin) return permissions();
      const tempRole = this.tempRole();
      return tempRole?.permissions ?? SessionService.allPermissions;
    });
    this.permissions = computed(() => arrToSet(permissionArr()));

    const roles = computed(() => this.state().roles ?? []);
    this.roles = computed(() => {
      if (!this.user()?.isSuperAdmin) return roles();
      const tempRole = this.tempRole();
      return tempRole?.role ? [tempRole.role] : SessionService.adminRoles;
    });

    this.authenticated = computed(() => !!this.user());
    this.admin = computed(() => !!this.user()?.isSuperAdmin);
    this.displayName = computed(() => formatUserName(this.user() ?? {firstName: 'Loading...'}));
  }

  hasPermissionSignal(permission: Permissions) {
    return computed(() => this.hasPermission(permission));
  }

  hasPermission(permission: Permissions) {
    return this.permissions().has(permission);
  }

  private readonly tempRole = signal<TempRoleData | undefined>(undefined);
  readonly hasTempRole = computed(() => !!this.tempRole());

  setTempRole(role: AdminAuthRole) {
    this.tempRole.set({role, permissions: role.permissions});
  }

  clearTempRole() {
    this.tempRole.set(undefined);
  }

  logOut() {
    this.tokenService.logOut();
  }

}

export interface SessionData {
  readonly user: Signal<AdminUserAuthModel | undefined>;

  readonly authenticated: Signal<boolean>;
  readonly admin: Signal<boolean>;
  readonly displayName: Signal<string>;

  readonly permissions: Signal<Set<Permissions>>;
  readonly roles: Signal<AuthRole[]>;
}

interface TempRoleData {
  role: AuthRole;
  permissions: Permissions[];
}
