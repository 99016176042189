
export function formatUserName(user: {firstName: string, lastName?: string}) {
  return `${user.firstName} ${user.lastName ?? ''}`.trimEnd();
}

export function appendUserAltName(name: string, altName?: string) {
  if (!altName) return name;
  return `${name} [${altName}]`;
}

export function formatUserInfoName(user: {firstName: string, lastName?: string, altName?: string}) {
  return appendUserAltName(formatUserName(user), user.altName);
}
